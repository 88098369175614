export enum QuotationPaths {
  PATH_UPLOAD_SELECTION = 'upload-selection',
  PATH_QUOTATIONS = 'quotations',
  PATH_QUOTATIONS_COUNT = 'count',
  PATH_QUOTATIONS_STATUS = 'quotations/status',
  PATH_REFRESH_SAP_PRICING = 'reload-from-sap',
  PATH_CUSTOMER_QUOTATION = 'customers/quotations',
  PATH_CURRENCIES = 'currencies',
  PATH_SAP_QUOTATION = 'quotation/create-sap-quotation',
  PATH_SAP_SYNC_STATUS = 'sap-sync-status',
  PATH_ATTACHMENTS = 'attachments',
  PATH_ATTACHMENT_DOWNLOAD = 'download',
  PURCHASE_ORDER_TYPES = 'purchase-order-types',
  OFFER_TYPES = 'offer-types',
}
