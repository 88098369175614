<div>
  <p
    class="flex flex-row flex-wrap text-body-2 text-medium-emphasis"
    [ngClass]="{
      'mb-2': marginBottom,
      '!text-caption': modalVersion,
      'flex-col': modalVersion && !modalFlexRow,
      'font-medium': modalVersion
    }"
  >
    <span class="pr-2" [ngClass]="{ 'text-low-emphasis': modalVersion }">
      <ng-content select="[label]"></ng-content>:
    </span>

    @if (!tooltipText) {
      <ng-container *ngTemplateOutlet="textContent"></ng-container>
    } @else {
      <span class="flex h-5 items-center justify-center overflow-hidden">
        <ng-container *ngTemplateOutlet="textContent"></ng-container>
        <gq-info-icon
          [text]="tooltipText"
          [displaySmall]="true"
          class="pl-1"
        ></gq-info-icon>
      </span>
    }

    <ng-template #textContent>
      <span class="text-high-emphasis">
        <ng-content select="[text]"></ng-content>
      </span>
    </ng-template>
  </p>
</div>
