import { Locale } from '@schaeffler/transloco/components';

export const LOCALE_DE = {
  id: 'de-DE',
  label: 'Deutsch (Deutschland)',
};

export const LOCALE_EN = {
  id: 'en-US',
  label: 'English (United States)',
};

export const AVAILABLE_LOCALES: Locale[] = [LOCALE_DE, LOCALE_EN];
