<div class="flex h-full items-center justify-start !text-[16px]">
  @if (valid) {
    <mat-icon
      class="!flex items-center justify-center !text-[16px]"
      [ngClass]="{
        'text-icon-link': !isErrorText,
        'text-icon-warning': isErrorText
      }"
      [matTooltip]="toolTipText"
      >check_circle</mat-icon
    >
  }
  @if (isLoading && !valid) {
    <mat-icon
      class="!flex items-center justify-center !text-[16px] text-medium-emphasis"
      [matTooltip]="toolTipText"
      >sync</mat-icon
    >
  }
  @if (!isLoading && !valid) {
    <mat-icon
      class="!flex items-center justify-center !text-[16px] text-error"
      [matTooltip]="toolTipText"
      >warning_amber</mat-icon
    >
  }
</div>
