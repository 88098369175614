<div
  class="flex h-full w-full flex-col items-center justify-center text-center"
  data-cy="pageNotFound"
  *transloco="let t"
>
  <div
    class="page-not-found"
    [ngClass]="{ 'show-easter-egg': showEasterEgg() }"
  ></div>
  <h4 class="mb-4 text-high-emphasis">
    {{ t('pageNotFound') }}
  </h4>
  <p class="whitespace-pre-line text-subtitle-1 text-medium-emphasis">
    {{ t('pageNotFoundMessage') }}
  </p>
  <div class="flex justify-center gap-4 p-8">
    <button
      mat-raised-button
      color="primary"
      routerLink="/"
      data-cy="visitHome"
    >
      {{ t('visitHomepage') }}
    </button>
  </div>
</div>
