@if (isFNumber && canDisplay) {
  <div
    class="flex h-full items-center overflow-hidden"
    *transloco="let t; read: 'shared.quotationDetailsTable'"
  >
    <div
      (click)="openDialog()"
      class="flex bg-primary items-center overflow-hidden rounded-md px-3 py-1 text-caption cursor-pointer"
    >
      <span class="text-white-high-emphasis text-[8px]"
        >{{ t('priceAssistantButton') | uppercase }}
      </span>
    </div>
  </div>
}
