<div class="py-4 px-5">
  <schaeffler-language-select
    [reloadOnLanguageChange]="true"
  ></schaeffler-language-select>
  <div>
    <schaeffler-locale-select
      class="mt-4 block"
      [reloadOnLocaleChange]="true"
      [availableLocales]="availableLocales"
      [defaultLocale]="defaultLocale"
    ></schaeffler-locale-select>
  </div>

  <div class="pt-4">
    <gq-roles-rights></gq-roles-rights>
  </div>
  <div
    *transloco="let tr; read: 'userSettings'"
    class="text-body-1 text-medium-emphasis"
    [innerHTML]="
      tr('contactInformation', { serivceNowAdress: serivceNowAdress })
    "
  ></div>
</div>
