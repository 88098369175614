<ng-container *transloco="let t; read: 'pricingDetails.materialDetails'">
  <div *ngrxLet="quotationDetail.material as materialDetails">
    <gq-label-text>
      <span label>{{ t('baseUoM') }}</span>
      <span text>{{ materialDetails.baseUoM | uom | nullableString }}</span>
    </gq-label-text>
    <gq-label-text>
      <span label>{{ t('deliveryUnit') }}</span>
      <span text>{{
        quotationDetail.deliveryUnit?.toString() | nullableString
      }}</span>
    </gq-label-text>
    <gq-label-text>
      <span label>{{ t('materialType') }}</span>
      <span text>{{ materialDetails.materialType | nullableString }}</span>
    </gq-label-text>
    <gq-label-text [marginBottom]="false">
      <span label>{{ t('ean11') }}</span>
      <span text>{{ materialDetails.ean11 | nullableString }}</span>
    </gq-label-text>
  </div>
</ng-container>
