export enum ErrorId {
  NotAllowedToAccessCustomer = 1,
  NotFoundCustomer = 2,
  NotFoundQuotation = 3,
  NotFoundQuotationDetail = 4,
  NotFoundFPricing = 5,
  NotFoundProductType = 6,
  NotFoundMarketValueDriverQuestionid = 7,
  NotFoundMarketValueDriverOptionid = 8,
  NotFoundMaterials = 10,
  ServiceUnavailableSapSyncFailed = 21,
}
