export const GREATER_CHINA_SALES_ORGS: string[] = [
  '0083',
  '0199',
  '0259',
  '0289',
  '0291',
  '0369',
  '0371',
  '8199',
];
