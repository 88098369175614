<div *transloco="let t; read: 'pricingDetails.materialDetails'">
  <gq-label-text>
    <span label>{{ t('encodedInSalesOrg') }}</span>
    <span text>
      <div class="flex h-5 items-center">
        <div
          class="mr-2 h-3 w-3 rounded-full bg-primary"
          [ngClass]="{
            'bg-error': !materialSalesOrgDataAvailable
          }"
        ></div>
      </div>
    </span>
  </gq-label-text>
  <gq-label-text>
    <span label>{{ t('dchainMaterialStatus') }}</span>
    <span text>
      {{ materialSalesOrg?.materialStatus | nullableString }}
    </span>
  </gq-label-text>
  <gq-label-text [marginBottom]="false">
    <span label>{{ t('minimumOrderQuantity') }}</span>
    <span text>{{ materialSalesOrg?.minimumOrderQuantity }}</span>
  </gq-label-text>
</div>
