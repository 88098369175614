<h6 data-cy="title">
  {{ title }}

  <mat-icon
    data-cy="close-icon"
    class="float-right cursor-pointer text-medium-emphasis"
    (click)="emitCloseDialog()"
  >
    close
  </mat-icon>
</h6>
