<div class="flex flex-row items-center gap-2">
  <span class="truncate">
    <a
      [href]="url"
      (click)="navigate($event)"
      class="text-link hover:underline"
      >{{ itemId }}</a
    >
  </span>

  @if (isRfq) {
    <img
      class="pt-[2px]"
      [ngSrc]="imagePath"
      width="24"
      height="24"
      priority
      [matTooltip]="toolTipText"
    />
  }
</div>
