<div
  class="flex h-full flex-col gap-4"
  *transloco="let t; read: 'processCaseView.editingCommentModal'"
>
  <gq-dialog-header
    *ngrxLet="quotationIsEditable$ as editable"
    [title]="t('title', { id: modalData.quotationItemId, editable })"
    (closeDialog)="closeDialog()"
  >
  </gq-dialog-header>
  @if ((updateLoading$ | ngrxPush) === false) {
    <div class="flex h-full flex-col">
      <div class="h-full">
        <mat-form-field class="w-full">
          <textarea
            class="text-overline !leading-7"
            [formControl]="commentFormControl"
            appearance="fill"
            type="text"
            maxlength="1000"
            cdkTextareaAutosize
            #autosize="cdkTextareaAutosize"
            cdkAutosizeMinRows="1"
            cdkAutosizeMaxRows="4"
            matInput
            #comment
            data-cy="comment-modal_comment-input"
          ></textarea>
          <mat-hint align="end"> {{ comment.value.length }} / 1000</mat-hint>
        </mat-form-field>
      </div>
      @if (quotationIsEditable$ | ngrxPush) {
        <div>
          <button
            class="!pl-8 !pr-8"
            color="primary"
            mat-raised-button
            [disabled]="commentDisabled || commentFormControl.invalid"
            (click)="confirmComment()"
            data-cy="comment-modal_comment-confirm-btn"
          >
            <span> {{ t('confirm') | uppercase }} </span>
          </button>
          <button
            class="!ml-6 !pl-8 !pr-8"
            color="primary"
            mat-stroked-button
            (click)="closeDialog()"
            data-cy="comment-modal_comment-cancel-btn"
          >
            <span> {{ t('cancel') | uppercase }} </span>
          </button>
        </div>
      }
    </div>
  } @else {
    <schaeffler-loading-spinner
      class="h-full"
      backgroundColor="rgba(255,255,255, 0.1)"
      [relative]="true"
    ></schaeffler-loading-spinner>
  }
</div>
