<div
  class="flex h-full items-center overflow-hidden"
  *transloco="let t; read: 'shared.sapStatusLabels'"
>
  <schaeffler-tag
    [type]="tagType"
    [styleClass]="'uppercase'"
    size="small"
    [withDot]="syncedStatus !== syncPending"
  >
    <span
      class="truncate text-[8px] font-bold"
      [matTooltip]="errorCode ? t('errorCodes.' + errorCode) : undefined"
    >
      {{
        t('sapSyncStatus', {
          sapSyncStatus: syncedStatus
        })
      }}
    </span>
  </schaeffler-tag>
</div>
