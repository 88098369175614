<div>
  <span class="float-left mr-[10px]">
    {{ cellValue }}
  </span>
  <mat-icon
    class="cursor-pointer align-text-top !text-[16px] text-medium-emphasis"
    (click)="onIconClick()"
    >mode_edit</mat-icon
  >
</div>
