<div
  class="flex h-full w-full flex-col items-center justify-center text-center"
  data-cy="forbidden"
  *transloco="let t"
>
  <div class="forbidden"></div>
  <h4 class="mb-4 text-high-emphasis">
    {{ t(routeData.headingText || 'forbiddenHeadingDefault') }}
  </h4>
  <p
    class="whitespace-pre-line text-subtitle-1 text-medium-emphasis"
    [innerHTML]="t(routeData.messageText || 'forbiddenMessageDefault')"
  ></p>
  <div class="flex justify-center gap-4 p-8">
    @if (routeData.action) {
      @if (routeData.action === 'event') {
        <button
          mat-raised-button
          color="primary"
          (click)="onActionButtonClick()"
        >
          {{ t(routeData.actionButtonText || 'actionButtonTextDefault') }}
        </button>
      } @else {
        @if (routeData.action) {
          <a
            [href]="routeData.action"
            mat-raised-button
            color="primary"
            data-cy="requestAccess"
          >
            {{ t(routeData.actionButtonText || 'actionButtonTextDefault') }}
          </a>
        }
      }
    }
    @if (!routeData.hideHomeButton) {
      <button
        mat-raised-button
        [color]="routeData.action ? undefined : 'primary'"
        routerLink="/"
        data-cy="visitHome"
      >
        {{ t(routeData.homeButtonText || 'homeButtonTextDefault') }}
      </button>
    }
  </div>
</div>
