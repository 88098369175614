<div
  class="flex h-full flex-col"
  *transloco="let t; read: 'shared.editingMaterialModal'"
>
  <gq-dialog-header [title]="t('title')" (closeDialog)="closeDialog()">
  </gq-dialog-header>
  <form [formGroup]="editFormGroup" class="mt-6 flex h-full flex-col">
    <div class="flex flex-col">
      <div class="flex flex-row gap-6">
        @if (
          autoCompleteFacade.materialDescForEditMaterial$ | ngrxPush;
          as materialDesc
        ) {
          <div class="flex-1">
            <gq-autocomplete-input
              #materialDescInput
              [filterName]="materialDesc.filter"
              [fitContent]="true"
              [options]="materialDesc.options"
              [autocompleteLoading]="
                autoCompleteFacade.materialDescAutocompleteLoading$ | ngrxPush
              "
              (autocomplete)="autoCompleteFacade.autocomplete($event)"
              (inputContent)="materialHasInput($event)"
              (isValid)="materialInputValid($event)"
              (unselected)="
                autoCompleteFacade.unselectOptions(materialDesc.filter)
              "
              (added)="
                autoCompleteFacade.selectMaterialNumberOrDescription(
                  $event,
                  materialDesc.filter
                )
              "
            ></gq-autocomplete-input>
          </div>
        }
        @if (
          autoCompleteFacade.materialNumberForEditMaterial$ | ngrxPush;
          as materialNumber
        ) {
          <div class="flex-1">
            <gq-autocomplete-input
              #materialNumberInput
              [filterName]="materialNumber.filter"
              [fitContent]="true"
              [options]="materialNumber.options"
              [autocompleteLoading]="
                autoCompleteFacade.materialNumberAutocompleteLoading$ | ngrxPush
              "
              (isValid)="materialInputValid($event)"
              (autocomplete)="autoCompleteFacade.autocomplete($event)"
              (inputContent)="materialHasInput($event)"
              (unselected)="
                autoCompleteFacade.unselectOptions(materialNumber.filter)
              "
              (added)="
                autoCompleteFacade.selectMaterialNumberOrDescription(
                  $event,
                  materialNumber.filter
                )
              "
            ></gq-autocomplete-input>
          </div>
        }
      </div>
      <div class="mt-4 flex flex-row gap-6">
        <div class="flex-1">
          <mat-form-field appearance="outline" class="w-full">
            <mat-label>
              {{ t('quantity.label') }}
            </mat-label>

            <input
              class="text-overline"
              autocomplete="off"
              matInput
              #valueInput
              min="1"
              type="number"
              formControlName="quantity"
              (keydown)="handleQuantityKeyDown($event)"
            />
            <mat-hint>{{ t('quantity.hint') }}</mat-hint>
            @if (editFormGroup.get('quantity')?.errors?.required) {
              <mat-error>{{ t('quantity.error') }}</mat-error>
            }
          </mat-form-field>
        </div>
        <ng-container
          ><div class="flex-1">
            <mat-form-field appearance="outline" class="w-full">
              <mat-label>
                {{ t('targetPrice.label') }}
              </mat-label>

              <input
                class="text-overline"
                autocomplete="off"
                matInput
                #targetPriceInput
                type="text"
                formControlName="targetPrice"
              />
              <mat-hint>{{ t('targetPrice.hint') }}</mat-hint>
              @if (editFormGroup.get('targetPrice')?.errors?.invalidPrice) {
                <mat-error>{{ t('targetPrice.error') }}</mat-error>
              }
            </mat-form-field>
          </div></ng-container
        >
      </div>
    </div>
  </form>

  <div class="pt-8">
    <button
      class="!pl-4 !pr-4"
      color="primary"
      mat-raised-button
      (click)="update()"
      [disabled]="!updateRowEnabled"
    >
      <span> {{ t('update') | uppercase }} </span>
    </button>
    <button
      class="!ml-4 !pl-4 !pr-4"
      color="primary"
      (click)="closeDialog()"
      mat-stroked-button
    >
      <span> {{ t('cancel') | uppercase }} </span>
    </button>
  </div>
</div>
