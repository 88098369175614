/* eslint-disable no-template-curly-in-string */
import { AgGridLocale } from '../models/ag-grid-locale.interface';
export const AG_GRID_LOCALE_DE: AgGridLocale = {
  // Set Filter
  selectAll: '(Alle auswählen)',
  selectAllSearchResults: '(Alle Suchergebnisse auswählen)',
  searchOoo: 'Suche...',
  blanks: '(Leer)',
  noMatches: 'Keine Treffer',

  // Number Filter & Text Filter
  filterOoo: 'Filter...',
  equals: 'Gleich',
  notEqual: 'Ungleich',
  blank: 'Leer',
  notBlank: 'Nicht Leer',
  empty: 'Eins auswählen',

  // Number Filter
  lessThan: 'Kleiner als',
  greaterThan: 'Größer als',
  lessThanOrEqual: 'Kleiner oder gleich',
  greaterThanOrEqual: 'Größer oder gleich',
  inRange: 'Zwischen',
  inRangeStart: 'von',
  inRangeEnd: 'bis',

  // Text Filter
  contains: 'Enthält',
  notContains: 'Enthält nicht',
  startsWith: 'Fängt an mit',
  endsWith: 'Endet mit',

  // Date Filter
  dateFormatOoo: 'jjjj-mm-tt',

  // Filter Conditions
  andCondition: 'UND',
  orCondition: 'ODER',

  // Filter Buttons
  applyFilter: 'Anwenden',
  resetFilter: 'Zurücksetzen',
  clearFilter: 'Löschen',
  cancelFilter: 'Zurück',

  // Filter Titles
  textFilter: 'Text Filter',
  numberFilter: 'Zahlen Filter',
  dateFilter: 'Datum Filter',
  setFilter: 'Filter',

  // Side Bar
  columns: 'Spalten',
  filters: 'Filter',

  // columns tool panel
  pivotMode: 'Pivot Modus',
  groups: 'Reihen Gruppierungen',
  rowGroupColumnsEmptyMessage: 'Hier ziehen, um Zeilengruppen festzulegen',
  values: 'Werte',
  valueColumnsEmptyMessage: 'Hier ziehen, um zu aggregieren',
  pivots: 'Spalten Label',
  pivotColumnsEmptyMessage:
    'Ziehen Sie hier, um Spaltenbeschriftungen festzulegen',

  // Header of the Default Group Column
  group: 'Gruppe',

  // Other
  loadingOoo: 'Lädt...',
  noRowsToShow: 'Keine Reihen zum Anzeigen',
  enabled: 'Aktiviert',

  // Menu
  pinColumn: 'Spalte anheften',
  pinLeft: 'Links anheften',
  pinRight: 'Rechts anheften',
  noPin: 'Nicht anheften',
  valueAggregation: 'Werte Aggregation',
  autosizeThiscolumn: 'Automatische Größenanpassung dieser Spalte',
  autosizeAllColumns: 'Automatische Größenanpassung aller Spalten',
  groupBy: 'Gruppierung nach',
  ungroupBy: 'Gruppierung aufheben nach',
  addToValues: '${variable} zu Werten hinzufügen',
  removeFromValues: '${variable} von Werten entfernen',
  addToLabels: '${variable} zu Labeln hinzufügen',
  removeFromLabels: '${variable} von Labeln entfernen',
  resetColumns: 'Spalten zurücksetzen',
  expandAll: 'Alle expandieren',
  collapseAll: 'Alle schließen',
  copy: 'Kopieren',
  ctrlC: 'Strg+C',
  copyWithHeaders: 'Kopieren mit Kopfzeilen',
  copyWithGroupHeaders: 'Kopieren mit Kopfzeilen Gruppierungen',
  paste: 'Einfügen',
  ctrlV: 'Strg+V',
  export: 'Exportieren',
  csvExport: 'CSV Export',
  excelExport: 'Excel Export',

  // Enterprise Menu Aggregation and Status Bar
  sum: 'Summe',
  min: 'Min',
  max: 'Max',
  none: 'Keine',
  count: 'Anzahl',
  avg: 'Durchschnitt',
  filteredRows: 'Filtern',
  selectedRows: 'Auswählen',
  totalRows: 'Gesamte Reihen',
  totalAndFilteredRows: 'Reihen',
  more: 'Mehr',
  to: 'zu',
  of: 'von',
  page: 'Seite',
  nextPage: 'Nächste Seite',
  lastPage: 'Letzte Seite',
  firstPage: 'Erste Seite',
  previousPage: 'Vorherige Seite',

  // Pivoting
  pivotColumnGroupTotals: 'Total',

  // Enterprise Menu (Charts)
  pivotChartAndPivotMode: 'Pivot Chart & Pivot Modus',
  pivotChart: 'Pivot Chart',
  chartRange: 'Chart Bereich',

  columnChart: 'Spalte',
  groupedColumn: 'Gruppiert',
  stackedColumn: 'Gestapelt',
  normalizedColumn: '100% Gestapelt',

  barChart: 'Bar',
  groupedBar: 'Gruppiert',
  stackedBar: 'Gestapelt',
  normalizedBar: '100% Gestapelt',

  pieChart: 'Kreisdiagramm',
  pie: 'Kreis',
  doughnut: 'Donut',

  line: 'Linie',

  xyChart: 'X Y (Streuung)',
  scatter: 'Streuung',
  bubble: 'Blase',

  areaChart: 'Flächendiagramm',
  area: 'Fläche',
  stackedArea: 'Gestapelt',
  normalizedArea: '100% Gestapelt',

  histogramChart: 'Histogramm',

  // Charts
  pivotChartTitle: 'Pivot Chart',
  rangeChartTitle: 'Bereichschart',
  settings: 'Einstellungen',
  data: 'Daten',
  format: 'Format',
  categories: 'Kategorien',
  defaultCategory: '(Keine)',
  series: 'Serien',
  xyValues: 'X Y Werte',
  paired: 'Gepaarter Modus',
  axis: 'Achse',
  navigator: 'Navigator',
  color: 'Farbe',
  thickness: 'Dicke',
  xType: 'X Typ',
  automatic: 'Automatisch',
  category: 'Kategorie',
  number: 'Nummer',
  time: 'Zeit',
  xRotation: 'X Rotation',
  yRotation: 'Y Rotation',
  ticks: 'Haken',
  width: 'Breite',
  height: 'Höhe',
  length: 'Länge',
  padding: 'Innenabstände',
  spacing: 'Abstände',
  chart: 'Tabelle',
  title: 'Titel',
  titlePlaceholder: 'Tabellentitel - Doppelklick zum Editieren',
  background: 'Hintergrund',
  font: 'Schriftart',
  top: 'Oben',
  right: 'Rechts',
  bottom: 'Unten',
  left: 'Links',
  labels: 'Label',
  size: 'Größe',
  minSize: 'Minimale Größe',
  maxSize: 'Maximale Größe',
  legend: 'Legende',
  position: 'Position',
  markerSize: 'Größe der Markierung',
  markerStroke: 'Markierungsstrich',
  markerPadding: 'Markiererabstand',
  itemSpacing: 'Item Abstände',
  itemPaddingX: 'Item Abstände X',
  itemPaddingY: 'Item Abstände Y',
  layoutHorizontalSpacing: 'Horizontale Abstände',
  layoutVerticalSpacing: 'Vertikale Abstände',
  strokeWidth: 'Strichstärke',
  offset: 'Versatz',
  offsets: 'Versatz',
  tooltips: 'Tooltips',
  callout: 'Aufruf',
  markers: 'Marker',
  shadow: 'Schatten',
  blur: 'Unschärfe',
  xOffset: 'X Versatz',
  yOffset: 'Y Versatz',
  lineWidth: 'Linienbreite',
  normal: 'Normal',
  bold: 'Bold',
  italic: 'Italic',
  boldItalic: 'Bold Italic',
  predefined: 'Vordefiniert',
  fillOpacity: 'Füllungsdichte',
  strokeOpacity: 'Linientransparenz',
  histogramBinCount: 'Platzanzahl',
  columnGroup: 'Spalte',
  barGroup: 'Bar',
  pieGroup: 'Pie',
  lineGroup: 'Linie',
  scatterGroup: 'X Y (Streuung)',
  areaGroup: 'Area',
  histogramGroup: 'Histogramm',
  groupedColumnTooltip: 'Gruppiert',
  stackedColumnTooltip: 'Gestapelt',
  normalizedColumnTooltip: '100% Gestapelt',
  groupedBarTooltip: 'Gruppiert',
  stackedBarTooltip: 'Stacked',
  normalizedBarTooltip: '100% Gestapelt',
  pieTooltip: 'Kreis',
  doughnutTooltip: 'Donut',
  lineTooltip: 'Linie',
  groupedAreaTooltip: 'Area',
  stackedAreaTooltip: 'Gestapelt',
  normalizedAreaTooltip: '100% Gestapelt',
  scatterTooltip: 'Streuung',
  bubbleTooltip: 'Blase',
  histogramTooltip: 'Histogramm',
  noDataToChart: 'Es sind keine Daten verfügbar, die kartiert werden können.',
  pivotChartRequiresPivotMode:
    'Pivot Diagramm benötigt aktivierten Pivot Modus.',
  chartSettingsToolbarTooltip: 'Menü',
  chartLinkToolbarTooltip: 'Verknüft mit Gitter',
  chartUnlinkToolbarTooltip: 'Unverknüft von Gitter',
  chartDownloadToolbarTooltip: 'Diagramm herunterladen',

  // ARIA
  ariaHidden: 'versteckt',
  ariaVisible: 'sichtbar',
  ariaChecked: 'ausgewählt',
  ariaUnchecked: 'unausgewählt',
  ariaIndeterminate: 'unbestimmt',
  ariaDefaultListName: 'Liste',
  ariaColumnSelectAll: 'Alle Spalten auswählen umschalten',
  ariaInputEditor: 'Eingabe-Editor',
  ariaDateFilterInput: 'Datum Filter Eingabe',
  ariaFilterList: 'Filter Liste',
  ariaFilterInput: 'Filter Eingabe',
  ariaFilterColumnsInput: 'Filter Spalten Eingabe',
  ariaFilterValue: 'Filter Wert',
  ariaFilterFromValue: 'Filter von Wert',
  ariaFilterToValue: 'Filter nach Wert',
  ariaFilteringOperator: 'Operator filtern',
  ariaColumn: 'Spalte',
  ariaColumnList: 'Spalten Liste',
  ariaColumnGroup: 'Spaltengruppe',
  ariaRowSelect: 'Drücken Sie die Leertaste, um diese Zeile auszuwählen.',
  ariaRowDeselect:
    'Drücken Sie die Leertaste, um die Auswahl dieser Zeile aufzuheben.',
  ariaRowToggleSelection:
    'Drücken Sie die Leertaste, um die Zeilenauswahl umzuschalten.',
  ariaRowSelectAll:
    'Drücken Sie die Leertaste, um die Auswahl aller Zeilen umzuschalten.',
  ariaToggleVisibility:
    'Drücken Sie die Leertaste, um die Sichtbarkeit umzuschalten.',
  ariaSearch: 'Suche',
  ariaSearchFilterValues: 'Suche Filter Werte',

  ariaRowGroupDropZonePanelLabel: 'Zeilengruppe',
  ariaValuesDropZonePanelLabel: 'Werte',
  ariaPivotDropZonePanelLabel: 'Spalten Label',
  ariaDropZoneColumnComponentDescription: 'Drücken Sie Löschen zum Entfernen',
  ariaDropZoneColumnValueItemDescription:
    'Drücken Sie ENTER, um die Aggregationsart zu ändern',

  // ARIA Labels for Dialogs
  ariaLabelColumnMenu: 'Spalten Menü',
  ariaLabelCellEditor: 'Zellen editieren',
  ariaLabelDialog: 'Dialog',
  ariaLabelSelectField: 'Auswahl Feld',
  ariaLabelTooltip: 'Tooltip',
  ariaLabelContextMenu: 'Kontext Menü',
  ariaLabelSubMenu: 'Untermenü',
  ariaLabelAggregationFunction: 'Aggregationsfunktion',

  // Number Format (Status Bar, Pagination Panel)
  thousandSeparator: '.',
  decimalSeparator: ',',
};
