@if (rating) {
  <div>
    @for (i of [].constructor(rating); track $index) {
      <mat-icon class="align-text-top !text-[16px]">star</mat-icon>
    }
    @for (i of [].constructor(3 - rating); track $index) {
      <mat-icon class="align-text-top !text-[16px]">star_border</mat-icon>
    }
  </div>
} @else {
  -
}
