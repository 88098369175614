<ng-container
  *transloco="let t; scope: 'f-pricing'; read: 'fPricing.pricingAssistantModal'"
>
  <ng-container *ngrxLet="quotationDetail.material as material">
    <gq-dialog-header
      [title]="
        t('materialDetailsDialogTitle', {
          materialDescription: material.materialDescription
        })
      "
      (closeDialog)="closeDialog()"
    ></gq-dialog-header>
    <mat-card appearance="outlined" class="mt-4 p-4">
      <div class="flex flex-row gap-6">
        <div class="w-1/2">
          <gq-material-basic
            [quotationDetail]="quotationDetail"
          ></gq-material-basic>
          <gq-horizontal-divider></gq-horizontal-divider>
          <gq-product-details
            [quotationDetail]="quotationDetail"
          ></gq-product-details>
        </div>

        <div class="w-1/2">
          <gq-dimension-details
            [materialDetails]="material"
          ></gq-dimension-details>
          <gq-horizontal-divider></gq-horizontal-divider>
          <gq-material-additional
            [quotationDetail]="quotationDetail"
          ></gq-material-additional>
          <gq-horizontal-divider></gq-horizontal-divider>
          <gq-material-sales-org-details
            [materialSalesOrg]="materialSalesOrg$ | ngrxPush"
            [materialSalesOrgDataAvailable]="
              materialSalesOrgDataAvailable$ | ngrxPush
            "
          ></gq-material-sales-org-details>
        </div>
      </div>
    </mat-card>
  </ng-container>
</ng-container>
