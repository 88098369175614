<ng-container *ngrxLet="fPricingData$ | ngrxPush as fPricingData">
  <ng-container
    *transloco="
      let t;
      scope: 'f-pricing';
      read: 'fPricing.pricingAssistantModal'
    "
  >
    <mat-dialog-content>
      <div class="flex flex-col h-full w-full">
        <gq-pricing-assistant-header
          [material]="material"
          (closeDialog)="closeDialog()"
          (showMore)="showMore()"
        ></gq-pricing-assistant-header>

        <!-- content and possible overlays -->
        <div class="flex flex-col w-full h-full min-h-[inherit] relative">
          @if (visibleOverlay === overlayToShowEnum.comparisonScreen) {
            <gq-overlay>
              <ng-container
                [ngTemplateOutlet]="comparisonScreenView"
              ></ng-container>
            </gq-overlay>
          }
          @if (fPricingDataLoading$ | ngrxPush) {
            <gq-overlay class="z-10">
              <schaeffler-loading-spinner></schaeffler-loading-spinner>
            </gq-overlay>
          }
          <ng-container [ngTemplateOutlet]="gqPricingView"></ng-container>
          <ng-container [ngTemplateOutlet]="manualPricingView"></ng-container>
        </div>
        <!--footer-->
        @if (
          visibleOverlay !== overlayToShowEnum.comparisonScreen &&
          !(fPricingDataLoading$ | ngrxPush)
        ) {
          <div class="flex flex-col">
            <div class="mb-4 mt-2 -mx-4 h-px bg-selected-overlay"></div>
            <div class="flex justify-start">
              @if (visibleOverlay === overlayToShowEnum.gqPricing) {
                <ng-container
                  [ngTemplateOutlet]="gqPricingConfirmButton"
                ></ng-container>
              }
              @if (visibleOverlay === overlayToShowEnum.manualPricing) {
                <ng-container
                  [ngTemplateOutlet]="manualConfirmButton"
                ></ng-container>
              }

              <button
                color="primary"
                class="text-button font-medium"
                mat-stroked-button
                (click)="closeDialog()"
              >
                {{ t('cancelButton') }}
              </button>
            </div>
          </div>
        }
      </div>
    </mat-dialog-content>
    <!-- templates -->
    <!--confirmButtons-->
    <ng-template #manualConfirmButton>
      <button
        color="primary"
        class="!mr-4 text-button font-medium"
        mat-raised-button
        [disabled]="
          !fPricingData?.quotationIsActive ||
          !manualPriceToDisplay ||
          (manualPriceInputInvalidOrUnchanged && !commentValidAndChanged)
        "
        (click)="confirmManualPrice()"
      >
        {{ t('confirmButton') }}
      </button>
    </ng-template>
    <ng-template #gqPricingConfirmButton>
      <button
        color="primary"
        class="!mr-4 text-button font-medium"
        mat-raised-button
        [disabled]="
          gqPricingConfirmButtonDisabled || !fPricingData?.quotationIsActive
        "
        (click)="confirmGqPrice()"
      >
        {{ t('confirmButton') }}
      </button>
    </ng-template>
    <ng-template #priceButtons>
      <div class="flex flex-row justify-between w-full gap-3 py-4">
        <gq-price-button
          class="w-full"
          [priceLabelKey]="'gqPriceLabel'"
          [priceValue]="fPricingData?.calculations?.finalPrice"
          [currency]="fPricingData?.currency"
          [gpmValue]="fPricingData?.calculations?.gpm"
          [icon]="
            fPricingData?.marketValueDriverWarningLevel !==
            marketValueDriverWarningLevel.COMPLETE
              ? 'warning_amber'
              : ''
          "
          [iconColor]="
            fPricingData?.marketValueDriverWarningLevel ===
            marketValueDriverWarningLevel.INCOMPLETE
              ? 'text-orange'
              : fPricingData?.marketValueDriverWarningLevel ===
                  marketValueDriverWarningLevel.UNSET
                ? 'text-error'
                : ''
          "
          [iconTooltip]="
            fPricingData?.marketValueDriverWarningLevel ===
            marketValueDriverWarningLevel.INCOMPLETE
              ? t('marketValueDriversWarningTooltip')
              : fPricingData?.marketValueDriverWarningLevel ===
                  marketValueDriverWarningLevel.UNSET
                ? t('marketValueDriversErrorTooltip')
                : ''
          "
          [isSelected]="dialogData.priceSource === priceSourceEnum.GQ"
          [isActiveInDialog]="visibleOverlay === overlayToShowEnum.gqPricing"
          (priceButtonClicked)="gqPriceClicked()"
        ></gq-price-button>

        @if (showAddManualPriceButton) {
          <gq-price-button
            [ngClass]="{
              'pointer-events-none': !fPricingData?.quotationIsActive
            }"
            class="w-full"
            [isAddPriceButton]="true"
            (addPriceButtonClicked)="manualPriceClicked()"
          ></gq-price-button>
        }
        @if (!showAddManualPriceButton) {
          <gq-price-button
            class="w-full"
            [priceLabelKey]="'manualPriceLabel'"
            [priceValue]="manualPriceToDisplay"
            [gpmValue]="manualPriceGPMToDisplay"
            [currency]="fPricingData?.currency"
            [isSelected]="isInitiallyManualPrice"
            [isActiveInDialog]="
              visibleOverlay === overlayToShowEnum.manualPricing
            "
            (priceButtonClicked)="manualPriceClicked()"
          ></gq-price-button>
        }
      </div>
    </ng-template>

    <ng-template #gqPricingView>
      <div
        class="gq-pricing-view"
        [ngClass]="{
          hidden: visibleOverlay !== overlayToShowEnum.gqPricing,
          'flex flex-col h-full': visibleOverlay === overlayToShowEnum.gqPricing
        }"
      >
        <ng-container [ngTemplateOutlet]="priceButtons"></ng-container>
        <mat-card
          appearance="outlined"
          class="h-full"
          [ngClass]="
            visibleOverlay !== overlayToShowEnum.gqPricing
              ? 'collapse'
              : 'visible'
          "
        >
          <div class="flex flex-col h-full overflow-hidden">
            <gq-pricing-results
              [price]="fPricingData?.calculations?.finalPrice"
              [currency]="fPricingData?.currency"
              [gpm]="fPricingData?.calculations?.gpm"
              [confidence]="dialogData.gqRating"
            ></gq-pricing-results>
            <gq-pricing-tabs-wrapper
              class="h-full"
              [currency]="fPricingData?.currency"
              [referencePrice]="fPricingData?.referencePrice"
              [isDisabled]="!fPricingData?.quotationIsActive"
              [marketValueDriversValue]="
                fPricingData?.calculations?.absoluteMvdSurcharge
              "
              [marketValueDriverWarning]="
                fPricingData?.marketValueDriverWarningLevel
              "
              [marketValueDriverData]="fPricingData?.marketValueDriversDisplay"
              [technicalValueDriversValue]="
                fPricingData?.calculations?.absoluteTvdSurcharge
              "
              [technicalValueDriversTableItems]="
                fPricingData?.technicalValueDriversForDisplay
              "
              [sanityChecksTableItems]="fPricingData?.sanityChecksForDisplay"
              [sanityCheckValue]="
                fPricingData?.calculations?.sanityCheck?.value
              "
              [finalPrice]="fPricingData?.calculations?.finalPrice"
              [referencePriceRowData]="
                fPricingData?.comparableTransactionsForDisplay
              "
              [comparableTransactionsLoading]="
                comparableTransactionsLoading$ | ngrxPush
              "
              [comparableTransactionsAvailable]="
                fPricingData?.comparableTransactionsAvailable
              "
              (comparedMaterialClicked)="onComparedMaterialClicked($event)"
              (marketValueDriverTabActivated)="mvdTabClicked()"
            ></gq-pricing-tabs-wrapper>
          </div>
        </mat-card>
      </div>
    </ng-template>

    <ng-template #comparisonScreenView>
      <div class="pt-3">
        <gq-product-comparison
          [referenceMaterial]="material"
          [comparedMaterial]="materialToCompare"
          [materialInformation]="fPricingFacade.materialInformation$ | ngrxPush"
          [materialComparisonLoading]="materialComparisonLoading$ | ngrxPush"
          (closeView)="backToGqPricingPage()"
        ></gq-product-comparison>
      </div>
    </ng-template>

    <ng-template #manualPricingView>
      <div
        class="flex flex-col manual-pricing-view"
        [ngClass]="{
          hidden: visibleOverlay !== overlayToShowEnum.manualPricing
        }"
      >
        <ng-container [ngTemplateOutlet]="priceButtons"></ng-container>
        <gq-price-editing-modal
          class="px-2"
          [modalData]="manualPriceData"
          [isDialog]="false"
          [isDisabled]="!fPricingData?.quotationIsActive"
          (affectedKpiOutput)="manualPriceChanged($event)"
          (isInvalidOrUnchanged)="manualPriceInvalidOrUnchangedHandled($event)"
          [warningTemplate]="warningTemplate"
        ></gq-price-editing-modal>
      </div>
    </ng-template>

    <ng-template #warningTemplate>
      <div class="flex flex-col">
        @if (
          manualPriceToDisplay &&
          fPricingData?.calculations?.finalPrice &&
          manualPriceToDisplay < fPricingData?.calculations?.finalPrice
        ) {
          <gq-info-banner
            [infoText]="
              t('manualPrice.warningText', {
                value:
                  fPricingData?.calculations?.finalPrice - manualPriceToDisplay
                  | numberCurrency: fPricingData?.currency
              })
            "
          ></gq-info-banner>
        }

        <mat-form-field class="w-full pt-4" appearance="outline">
          <mat-label>{{ t('manualPrice.commentLabel') }}</mat-label>
          <textarea
            matInput
            class="h-20 max-h-32 !leading-6"
            [formControl]="comment"
            type="text"
            maxlength="200"
          ></textarea>
          <mat-hint align="end">
            {{ comment.value?.length || 0 }} / 200</mat-hint
          >
        </mat-form-field>
      </div>
    </ng-template>
  </ng-container>
</ng-container>
