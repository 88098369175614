@if (freeStock !== undefined) {
  <div class="flex flex-row items-center">
    <div
      class="mr-2 h-3 w-3 rounded-full bg-primary"
      [ngClass]="{
        '!bg-[#cb0b15]': freeStock === 0,
        '!bg-[#e9b300]': freeStock <= 10 && freeStock > 0
      }"
    ></div>
    <span>
      {{ freeStock }}
      {{ uom | uom | nullableString }}
    </span>
  </div>
} @else {
  <span>-</span>
}
