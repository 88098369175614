<div
  class="flex h-full items-center overflow-hidden"
  *transloco="let t; read: 'shared.quotationStatusLabels'"
>
  <schaeffler-tag
    [type]="tagType"
    [value]="t(status)"
    [styleClass]="'uppercase'"
    size="small"
    [withDot]="false"
  >
    @if (statusVerified === false) {
      <mat-icon
        class="ml-1 !text-[12px] !h-3 !w-3"
        [matTooltip]="t('ongoingSapVerification')"
        >hourglass_empty</mat-icon
      >
    }
  </schaeffler-tag>
</div>
