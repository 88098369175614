@if (detail) {
  <div>
    <span class="float-left w-full min-w-[100px] truncate">
      {{ detail.comment || '-' }}
    </span>
    <mat-icon
      class="text-medium-emphasis cursor-pointer align-text-top !text-[16px]"
      (click)="onIconClick()"
      >{{
        quotationStatus === quotationStatusEnum.ACTIVE &&
        sapSyncStatus !== sapSyncStatusEnum.SYNC_PENDING
          ? 'mode_edit'
          : 'edit_off'
      }}</mat-icon
    >
  </div>
}
