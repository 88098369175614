export enum UserRoles {
  BASIC = 'BASIC',
  MANUAL_PRICE = 'PRICE_MANUAL',
  COST_GPC = 'COST_GPC',
  COST_SQV = 'COST_SQV',
  REGION_WORLD = 'REGION_WORLD',
  REGION_GREATER_CHINA = 'REGION_GREATER_CHINA',
  REGION_AMERICAS = 'REGION_AMERICAS',
  SECTOR_ALL = 'SECTOR_ALL',
  REGION_PREFIX = 'REGION_',
  SECTOR_PREFIX = 'SECTOR_',
  COST_PREFIX = 'COST_',
}
