@switch (modalData.field) {
  @case (columnFields.GPI) {
    <gq-gpi-editing-modal [modalData]="modalData"></gq-gpi-editing-modal>
  }
  @case (columnFields.GPM) {
    <gq-gpm-editing-modal [modalData]="modalData"></gq-gpm-editing-modal>
  }
  @case (columnFields.DISCOUNT) {
    <gq-discount-editing-modal
      [modalData]="modalData"
    ></gq-discount-editing-modal>
  }
  @case (columnFields.PRICE) {
    <gq-price-editing-modal [modalData]="modalData"></gq-price-editing-modal>
  }
  @case (columnFields.TARGET_PRICE) {
    <gq-target-price-editing-modal
      [modalData]="modalData"
    ></gq-target-price-editing-modal>
  }
  @case (columnFields.ORDER_QUANTITY) {
    <gq-quantity-editing-modal
      [modalData]="modalData"
    ></gq-quantity-editing-modal>
  }
}
