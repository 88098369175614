<div
  class="left-0 top-0 z-10 flex h-full w-full items-center justify-center"
  [ngStyle]="{ backgroundColor: backgroundColor }"
  [ngClass]="{ absolute: !relative }"
>
  @if (useBearingLoadingSpinner) {
    <img
      [width]="diameter"
      [height]="diameter"
      class="animate-spin"
      [src]="bearingUrl"
    />
  } @else {
    <mat-spinner
      class="outline-none"
      [diameter]="diameter"
      [strokeWidth]="strokeWidth"
    ></mat-spinner>
  }
</div>
