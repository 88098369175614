<ng-container *transloco="let t; read: 'searchBarAdvanced'">
  <div class="flex flex-col h-full">
    <!-- header-->
    <div class="flex flex-row items-center w-full">
      <div class="flex flex-row items-center w-full gap-3">
        <mat-icon matSuffix class="pr-4 text-medium-emphasis">
          search
        </mat-icon>
        <div class="flex flex-col w-full pr-4">
          <input
            #inputField
            matInput
            color="primary"
            class="w-full leading-7 !outline-none z-10"
            [ngClass]="
              searchFormControl.hasError('minlengthDisplay')
                ? 'text-error'
                : 'text-high-emphasis'
            "
            type="text"
            [placeholder]="t('searchHint')"
            [formControl]="searchFormControl"
            (keydown.enter)="
              !(searchFormControl.invalid || searchFormControl.pristine)
                ? search()
                : null
            "
            cdkFocusInitial
          />
          <mat-error
            class="absolute pt-6"
            [ngClass]="
              searchFormControl.hasError('minlengthDisplay')
                ? 'visible'
                : 'collapse'
            "
            >{{
              t('minLengthError', { minLength: activeMinLengthForValidation })
            }}</mat-error
          >
        </div>
      </div>
      <div class="flex items-center">
        <mat-slide-toggle
          class="whitespace-nowrap uppercase text-caption"
          color="primary"
          labelPosition="before"
          [checked]="onlyUserCases"
          (change)="toggleOnlyUserCases()"
        >
          <span class="pr-2">
            {{ t('onlyMyCases') }}
          </span>
        </mat-slide-toggle>
        <span
          class="text-caption cursor-pointer text-medium-emphasis font-normal pl-6"
          (click)="clearDialog()"
        >
          {{ t('clear') }}
        </span>
        <span class="px-2 text-medium-emphasis"> | </span>
        <mat-icon
          class="float-right h-5 w-5 cursor-pointer text-medium-emphasis"
          (click)="closeDialog()"
        >
          close
        </mat-icon>
      </div>
    </div>

    <!-- content-->
    <div class="flex flex-col w-full h-full relative pb-2 pt-4">
      <mat-tab-group
        [(selectedIndex)]="tabIndex"
        (selectedIndexChange)="tabChanged()"
      >
        <mat-tab [label]="t('casesLabel')">
          <gq-cases-result-table
            [resetInputs$]="reset$"
            [casesResults]="casesResults"
            [loading]="loading$ | ngrxPush"
            (criteriaSelected)="casesCriteriaSelected($event)"
            (rowDoubleClicked)="closeDialog()"
          ></gq-cases-result-table>
        </mat-tab>
        <mat-tab [label]="t('materialsLabel')">
          <gq-materials-result-table
            [resetInputs$]="reset$"
            [materialsResults]="materialResults"
            [loading]="loading$ | ngrxPush"
            (criteriaSelected)="materialCriteriaSelected($event)"
            (rowDoubleClicked)="closeDialog()"
          ></gq-materials-result-table>
        </mat-tab>
      </mat-tab-group>
    </div>
    <!-- the footer-->
    <div class="flex flex-col">
      <div class="flex justify-start gap-2 pt-2">
        <button
          color="primary"
          class="text-button font-medium"
          [disabled]="searchFormControl.invalid || searchFormControl.pristine"
          mat-raised-button
          (click)="search()"
        >
          {{ t('searchButton') }}
        </button>
        <button
          color="primary"
          class="text-button font-medium"
          mat-stroked-button
          (click)="closeDialog()"
        >
          {{ t('cancelButton') }}
        </button>
      </div>
    </div>
  </div>
</ng-container>
