<div>
  <span
    *transloco="let t; read: 'shared.validation'"
    class="float-left w-full truncate"
    [ngClass]="{
      'text-error': isInvalidPriceError,
      'text-warning': isWarningEnabled
    }"
  >
    @if (isWarningEnabled) {
      <mat-icon
        [matTooltip]="
          t(warningTooltip, { deliveryUnit: params.data.deliveryUnit })
        "
        class="!text-caption mr-[10px] !w-3 cursor-help align-text-top"
        >warning_amber</mat-icon
      >
    }
    <span
      [ngClass]="{
        'bg-[#e9b300]':
          (simulatedQuotation$ | ngrxPush) &&
          params.formatValue((simulatedQuotation$ | ngrxPush)[params.field]) !==
            '-'
      }"
      class="p-1"
    >
      {{
        (simulatedQuotation$ | ngrxPush)
          ? params
              .formatValue((simulatedQuotation$ | ngrxPush)[params.field])
              ?.trim()
          : params.valueFormatted?.trim()
      }}
    </span>
  </span>
  @if (isCellEditingAllowed) {
    <mat-icon
      *hideIfQuotationNotActiveOrPending
      class="text-medium-emphasis cursor-pointer align-text-top !text-[16px]"
      (click)="onIconClick()"
      >mode_edit</mat-icon
    >
  }
</div>
<ng-template #novalue> </ng-template>
