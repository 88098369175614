<div
  class="flex items-center justify-start"
  *transloco="let t; read: 'shared.caseMaterial.addEntry'"
>
  @if (showDeleteButton$ | ngrxPush) {
    <button
      mat-stroked-button
      class="text-button"
      color="primary"
      (click)="deleteItems()"
      [matTooltip]="t('deleteInvalid.tooltip')"
    >
      {{ t('deleteInvalid.button') }}
    </button>
  }
</div>
