<div>
  <div
    *transloco="let t; read: 'userSettings.rolesRights'"
    class="mb-3 text-subtitle-2"
  >
    {{ t('title') }}
  </div>
  @if (allRoles$ | ngrxPush; as allRoles) {
    <div>
      @for (groupRoles of allRoles; track $index) {
        <div class="mb-4">
          <gq-roles-description
            [roles]="groupRoles.roles"
            [key]="groupRoles.key"
          ></gq-roles-description>
        </div>
      }
    </div>
  }
</div>
