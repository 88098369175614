export enum ApprovalEventType {
  STARTED = 'STARTED',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  FORWARDED = 'FORWARDED',
  CANCELLED = 'CANCELLED',
  PRE_APPROVED = 'PRE_APPROVED',
  AUTO_APPROVAL = 'AUTO_APPROVAL',
  RELEASED = 'RELEASED',
}
