<div
  class="flex flex-col items-center p-4 text-center"
  data-cy="underConstruction"
>
  <div class="page-under-construction mb-6"></div>
  <h4 class="mb-4 text-high-emphasis">
    {{ title }}
  </h4>
  <p class="text-subtitle-1 text-medium-emphasis">
    {{ message }}
  </p>
</div>
