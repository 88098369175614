<div class="flex w-full justify-center">
  <div
    class="flex w-full max-w-[720px] cursor-pointer items-center rounded-full bg-[#F5F5F5] py-2 px-4 text-body-2 text-medium-emphasis"
    *transloco="let t"
    (click)="openGlobalSearchModal()"
  >
    <mat-icon>search</mat-icon>
    <span class="ml-4 font-normal">
      {{ t('searchBarAdvanced.searchHint') }}
    </span>
  </div>
</div>
