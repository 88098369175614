<mat-accordion multi="true" *transloco="let t; read: translationKey">
  <mat-expansion-panel class="mat-elevation-z0 border-border border">
    <mat-expansion-panel-header class="!h-auto !pb-4 !pl-4 !pt-3">
      <mat-panel-title class="text-body-1">
        {{ t('title') }}
      </mat-panel-title>
    </mat-expansion-panel-header>
    @for (role of roles; track $index) {
      <div>
        <div class="border-border flex flex-row border-t">
          <div class="bg-primary text-caption bg-opacity-[0.07] px-6 pb-6 pt-3">
            {{ role }}
          </div>
          <div class="text-caption px-6 pb-6 pt-3">
            {{ t(role) }}
          </div>
        </div>
      </div>
    }
  </mat-expansion-panel>
</mat-accordion>
