export enum AppRoutePath {
  BasePath = '',
  ForbiddenPath = 'forbidden',
  ForbiddenCustomerPath = 'forbiddenCustomer',
  CaseViewPath = 'case-view',
  DetailViewPath = 'detail-view',
  ProcessCaseViewPath = 'process-case',
  CreateManualCasePath = 'create-manual-case',
  FeatureToggleConfig = 'feature-toggle-config',
}
